unit Fomenu;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls;

type
  TForm1 = class(TForm)
    WebButton1: TButton;
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.WebFormCreate(Sender: TObject);
begin
  asm
    window.document.dispatchEvent(new Event("DOMContentLoaded", {
    }));
  end;

end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebButton1 := TButton.Create(Self);

  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Color := clWhite;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 682;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    TabOrder := 0;
    Top := 0;
    Width := 1034;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Caption := 'WebButton1';
    WebButton1.Color := clNone;
    WebButton1.Font.Charset := ANSI_CHARSET;
    WebButton1.Font.Color := clBlack;
    WebButton1.Font.Height := -11;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Size := 8;
    WebButton1.Font.Style := [];
    WebButton1.Height := 25;
    WebButton1.Left := 269;
    WebButton1.Role := 'button';
    WebButton1.TabOrder := 0;
    WebButton1.Top := 85;
    WebButton1.Width := 100;
  finally
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.